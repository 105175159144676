import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import firebase from '../firebase/firebase'

interface ForgetPasswordProps {
  history? : any
}
interface ForgetPasswordStates {
  email : string;
}

class ForgetPassword extends React.Component<ForgetPasswordProps, ForgetPasswordStates> {
  constructor(props: ForgetPasswordProps) {
    super(props);

    this.state = {
      email : ""
    }
  }

  render() : JSX.Element {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={() => {
                    firebase.loginWithGoogle((status: string) => {
                      if(status === 'success') {
                        setTimeout(() => {
                          this.props.history.replace('/admin/index')
                        }, 1000)
                      } else {
                        alert("Login failed!")
                      }
                    })
                  }}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("../assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" autoComplete="new-email" value={this.state.email}
                           onChange={(e: any) => this.setState({email: e.target.value})}/>
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={this.resetPassword}>
                    Reset Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={this.register}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }

  register = () => {
    this.props.history.push("/auth/register")
  }

  resetPassword = () => {
    firebase.forgetPassWord(this.state.email)
    this.props.history.push('/admin/index')
  }
}

export default ForgetPassword;
