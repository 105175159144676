import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'

const config = {
    apiKey: "AIzaSyBEmKfZvLWDSgWXz4eNHRTlM2L9VtQyvMs",
    authDomain: "smartpredix-15ce5.firebaseapp.com",
    databaseURL: "https://smartpredix-15ce5.firebaseio.com",
    projectId: "smartpredix-15ce5",
    storageBucket: "smartpredix-15ce5.appspot.com",
    messagingSenderId: "966143464275",
    appId: "1:966143464275:web:299be83b89d7d0698c47a6",
    measurementId: "G-H3G6DGW9M6"
}

class Firebase {
    private auth : any;
    private db : any;
    private provider : any;

    constructor() {
        app.initializeApp(config)
        this.auth = app.auth()
        this.db = app.firestore()
        this.provider = new app.auth.GoogleAuthProvider();
    }

    login = (email: string, password: string) => {
        return this.auth.signInWithEmailAndPassword(email, password)
    }

    logout = () => {
        return this.auth.signOut()
    }

    async register(name: string, email: string, password: string) {
        await this.auth.createUserWithEmailAndPassword(email, password)
        return this.auth.currentUser.updateProfile({
            displayName: name
        })
    }

    addQuote = (quote: string) => {
        if(!this.auth.currentUser) {
            return alert('Not authorized')
        }

        return this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).set({
            quote
        })
    }

    isInitialized = () => {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }

    getCurrentUsername = () => {
        return this.auth.currentUser && this.auth.currentUser.displayName
    }

    getCurrentUserUUID = () => {
        return this.auth.currentUser && this.auth.currentUser.uid
    }

    getCurrentUserQuote = async () => {
        const quote = await this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).get()
        return quote.get('quote')
    }

    loginWithGoogle = (callback: any) => {
        this.auth.signInWithPopup(this.provider)
            .then((result: any) => {
            console.log("Success");
                callback('success')
        }).catch((error: any) => {
            console.log(error);
            console.log("Error occurred...")
            callback('fail')
        })
    }

    forgetPassWord = (email: string) => {
        return this.auth.sendPasswordResetEmail(email)
    }
}

const firebase = new Firebase()

export default firebase