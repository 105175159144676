import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from './layouts/Admin'
import AuthLayout from './layouts/Auth'
import WithSecurity from "./WithSecurity";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/admin"
                   render={(props: any) => <WithSecurity {...props}><AdminLayout {...props} /></WithSecurity>}/>
            <Route path="/auth" render={(props: any) => <AuthLayout {...props} />}/>
            <Redirect from="/" to="/auth/login"/>
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
