import firebase from "../firebase/firebase";

interface CloverRestApiOptions extends RequestInit{
    url ?: string
    uuid : string
    token : string
}

class CloverRestApi {
    private options : CloverRestApiOptions;

    constructor(options : CloverRestApiOptions) {
        this.options = options;
    }
    public get = (command : string) => {
        let uuid = firebase.getCurrentUserUUID()
        console.log(uuid)
        const proxyurl = "https://api.smartpredix.com/users/"+ uuid;
        // const request = `${this.options.url}/${this.options.mid}/${command}?access_token=${this.options.token}`
        // console.log(request)
        return fetch(proxyurl, {method: "GET", ...this.options})
            .then((response: Response) => {
                console.log('Response Received:', response);
                return response.json();
            })
            .catch(err => {
                console.log(err);
            });
    }

}

const cloverRestApi = new CloverRestApi({
    // url: "https://sandbox.dev.clover.com/v3/merchants/",
    uuid: firebase.getCurrentUserUUID(),
    token: "28cab161-f267-9b70-e5ea-c4fc6142f064"})

export default cloverRestApi;

