import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import firebase from '../firebase/firebase'

interface LoginProps {
  history? : any
  classes? : any
}
interface LoginState {
  email : string;
  password : string
}

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      email : "",
      password : ""
    }
  }

  render() : JSX.Element {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                {/*<Button*/}
                {/*  className="btn-neutral btn-icon"*/}
                {/*  color="default"*/}
                {/*  href="#pablo"*/}
                {/*  onClick={(e: any) => e.preventDefault()}*/}
                {/*>*/}
                {/*  <span className="btn-inner--icon">*/}
                {/*    <img*/}
                {/*      alt="..."*/}
                {/*      src={require("../assets/img/icons/common/github.svg")}*/}
                {/*    />*/}
                {/*  </span>*/}
                {/*  <span className="btn-inner--text">Github</span>*/}
                {/*</Button>*/}
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={() => {
                    firebase.loginWithGoogle((status: string) => {
                      if(status === 'success') {
                        setTimeout(() => {
                          this.props.history.replace('/admin/index')
                        }, 1000)
                      } else {
                        alert("Login failed!")
                      }
                    })
                  }} >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("../assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" autoComplete="new-email" value={this.state.email}
                           onChange={(e: any) => this.setState({email: e.target.value})}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" autoComplete="off" value={this.state.password}
                           onChange={(e : any) => this.setState({password : e.target.value})}/>
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={this.login}>
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={this.resetPassword}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={this.register}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }

  resetPassword = () => {
    this.props.history.push("/auth/reset")
  }

  register = () => {
    this.props.history.push("/auth/register")
  }

  login = async () => {
    try {
      await firebase.login(this.state.email, this.state.password)
      this.props.history.replace('/admin/index')
    } catch(error) {
      alert(error.message)
    }
  }
}

export default Login;
