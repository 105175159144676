import * as React from "react";
import { Line as CLine } from "react-chartjs-2";
import LineStore from "../../../store/LineStore";
import {toJS} from "mobx";
import {observer} from "mobx-react";


export interface LineOptions {
    color?: string
    zeroLineColor?: string
    callback?: (value: any) => string|void;
    labelCallback?: (item : any, data: any) => string;
}
export interface LineProps extends LineOptions {
    store: LineStore
    title: string;
}

@observer
export default class Line extends React.Component<LineProps, any>{
    render() {
        const {store, title,  ...props} = this.props
        const data = toJS(store.data)
        console.log(data)
        const labels = store.labels
        const options = Line.generateOptions(props)
        return (
            <CLine
                data={{
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            data: data
                        }
                    ]
                }}
                // data={chartExample1[this.state.data as any]}
                options={options}
                getDatasetAtEvent={e => console.log(e)}
            />
        );
    }

    public static generateOptions(props: LineOptions) {
        return  {
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            color: props.color,
                            zeroLineColor: props.zeroLineColor
                        },
                        ticks: {
                            callback: props.callback
                        }
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    label: props.labelCallback
                }
            }
        }
    }
}