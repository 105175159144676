import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import firebase from "../firebase/firebase";

interface RegisterProps {
  history ?: any
}
interface RegisterState {
  name : string
  email : string;
  password : string
}

class Register extends React.Component<RegisterProps, RegisterState> {

  constructor(props: RegisterProps) {
    super(props);
    this.state = {
      name : "",
      email : "",
      password : ""
    }
  }

  onRegister = async () => {
    try {
      const {name, email, password} = this.state
      await firebase.register(name, email, password)
      // await firebase.addQuote(quote)
      this.props.history.replace('/dashboard')
    } catch(error) {
      alert(error.message)
    }
  }

  render() : JSX.Element {
    const {name, email, password} = this.state
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
              <div className="text-center">
                {/*<Button*/}
                {/*  className="btn-neutral btn-icon mr-4"*/}
                {/*  color="default"*/}
                {/*  href="#pablo"*/}
                {/*  onClick={e => e.preventDefault()}*/}
                {/*>*/}
                {/*  <span className="btn-inner--icon">*/}
                {/*    <img*/}
                {/*      alt="..."*/}
                {/*      src={require("../assets/img/icons/common/github.svg")}*/}
                {/*    />*/}
                {/*  </span>*/}
                {/*  <span className="btn-inner--text">Github</span>*/}
                {/*</Button>*/}
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={() => {
                    firebase.loginWithGoogle((status: string) => {
                      if(status === 'success') {
                        setTimeout(() => {
                          this.props.history.replace('/admin/index')
                        }, 1000)
                      } else {
                        alert("Login failed!")
                      }
                    })
                  }}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("../assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign up with credentials</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Name" type="text" value={name}
                           onChange={e => this.setState({name : e.target.value})} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" autoComplete="new-email" value={email}
                           onChange={e => this.setState({email : e.target.value})}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" autoComplete="new-password" value={password}
                           onChange={e => this.setState({password : e.target.value})}/>
                  </InputGroup>
                </FormGroup>
                <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div>
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={this.onRegister}>
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Register;
