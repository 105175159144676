
export interface RestConnectorOptions extends RequestInit {
    url : string

}

export default class RestConnector {
    private options : RestConnectorOptions

    constructor(options : RestConnectorOptions) {
        this.options = options
    }

    public get = () => {
        console.log(this.options.url);
        return fetch(this.options.url, {method: "GET", ...this.options})
            .then((response: Response) => response.json())
            .then((data: any) => {
                console.log(data)

                if(data.status !== "success") {
                    throw new Error("Something happened " + data.status)
                }
                return data.data
            })
            .catch(err => {
                console.log(err);
            });
    }
}

