import * as React from "react";
import firebase from "./firebase/firebase";

interface WithSecurityStates {
    isLoggedIn : boolean
}

export default class WithSecurity extends React.Component<any, WithSecurityStates> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoggedIn: false
        }
    }
    componentDidMount() {
        firebase.isInitialized().then((value : any) => {
            this.setState({isLoggedIn: value})
            if (!firebase.getCurrentUsername()) {
                this.props.history.replace('/auth/login')
            } else {
                this.props.history.replace('/admin/index')
            }
        })
    }

    render() : any {
        if (this.state.isLoggedIn) {
            return this.props.children;
        }
        return null;
    }
}
